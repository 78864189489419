import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  register(userObj,fileData?) {
    return this.http.post(this.baseUrl+"/registration", fileData,{params:userObj});
  }
  
  sendOtp(phone: String) {
    return this.http.post(this.baseUrl+"/generateOtp/"+phone,null);
  }

  // login(loginPayload) {
  //   const headers = {
  //     'Authorization': 'Basic ' + btoa('CanaryText-client:devglan-secret'),
  //     'Content-type': 'application/x-www-form-urlencoded'
  //   }
  // return this.http.post('http://52.165.37.155:8000/oauth/token', loginPayload, { headers });
  // }
  

  login(username, password) {
  return this.http.get(this.baseUrl+"/token/", {params:{'username':username,'password':password}});
  }

}
